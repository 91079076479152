<template>
    <main-template>
        <template slot-scope="{logo}">
        <div>
            <template>
                <page-header :logo="logo">
                    <div class="md:flex justify-between w-full items-center">
                        Export PI/PC
                    </div>
                </page-header>
                <invoice-export-form @success="showReimportForm = true" v-if="!showReimportForm && action" :action="action"></invoice-export-form>
                <reimport-form v-if="showReimportForm && reimportaction" :action="reimportaction"></reimport-form>
                <div v-if="!showReimportForm" class="text-center pt-4 text-xs hover:underline text-gray-400 cursor-pointer" @click="showReimportForm = true">Already done this? - Skip to reimport</div>
            </template>
        </div>
        </template>
    </main-template>
</template>

<script>
    import FetchSirenRoot from "@/components/FetchSirenRoot";
    import FormAction from "@/v2/components/FormAction";
    import InvoiceExportForm from "@/v2/components/forms/InvoiceExportForm";
    import MainTemplate from "@/v2/templates/MainTemplate";
    import {mapGetters} from "vuex";
    import Loading from "@/components/Loading";
    import PageHeader from "@/v2/components/Header";
    import ReimportForm from "@/v2/components/forms/ReimportForm";
    import Siren from "super-siren";
    let follow = Siren.Helper.follow;
    export default {
    name: "ExportOrders",
        components: {
            FormAction,
            MainTemplate,
            Loading,
            FetchSirenRoot,
            PageHeader,
            InvoiceExportForm,
            ReimportForm
        },
        data(){
            return {
                showReimportForm: false,
                action: null,
                reimportaction: null
            }
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                // if(Object.keys(this.$route.query).length === 0){
                //     return '/orders';
                // }
                return this.$route.fullPath.replace('/v3','')
            },
            ...mapGetters({
                accessToken: "getAccessToken"
            }),
        },
        mounted () {
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            Siren.get(process.env.VUE_APP_API_URL + "/api/")
                .then(follow("orders"))
                .then(res => {
                    let siren = res.body;
                    this.action = siren.actions.filter(action => action.name === 'export-invoices').get('export-invoices');
                    this.reimportaction = siren.actions.filter(action => action.name === 'reimport-with-sage-numbers').get('reimport-with-sage-numbers');
                }, this);

        },
        methods: {

        }
    }

</script>

<style scoped>

</style>
